<script>
import Detail from "./detail";
import moment from "moment";
import PDF from "../pedidos/viewPdf/viewpdf.vue";
import MyView from "./viewUploads/MyView";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { http } from "@/helpers/easyindustriaapi/config";
import Boleto from "./boletoRemessa/boletoRem.vue"
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listPedidos: { type: Array, required: true },
    isBusy: { type: Boolean, default: false },
  },
  components: { Detail, PDF, MyView, Boleto },
  data() {
    return {
      pdfArray: [],
      xmlStrings: [],
      button_boleano: false,
      select_todos_checks: null,
      check_boll: true,
      files: [],
      objeto_show_pdf: null,
      titleBody: "Listagem das Compras",
      currentPedido: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        // {
        //   label: "ID",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-right",
        //   thClass: "text-center",
        // },
        {
          label: "Check",
          key: "check",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "Número",
          key: "nNf",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "nome_destinatario",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Chave NF",
        //   key: "chNFe",
        //   sortable: true,
        //   tdClass: "text-center",
        //   thClass: "text-center",
        //   thStyle: { "min-width": "110px" },
        // },
        // {
        //   label: "Protocolo",
        //   key: "nProt",
        //   sortable: true,
        //   size: "150px",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Série", key: "serie", sortable: true, thClass: 'text-center' },
        //{ label: "Tipo", key: "tipo_id", sortable: true, thClass: 'text-center' },
        {
          label: "Dt. Emissão",
          key: "dhRecbto",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatDateBR",
          thStyle: { "min-width": "145px" },
        },
        {
          label: "Valor",
          key: "valor",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { "min-width": "110px" },
        },
        // { label: "Ent./Saída", key: "datasaida", sortable: true, tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Total NF", key: "valortotalnota", sortable: true, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"
        // },
        // { label: "Vlr ICMS", key: "valoricms", sortable: true, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"
        // },
        // { label: "Vlr ICMS ST", key: "valoricmssubs", sortable: true, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"
        // },
        // { label: "Vlr IPI", key: "vipi", sortable: true, tdClass: 'text-right', thClass: 'text-center' ,
        //   formatter: "formatCurrency"
        // },
        // { label: "Vlr PIS", key: "vpis", sortable: true, tdClass: 'text-right', thClass: 'text-center' ,
        //   formatter: "formatCurrency"
        // },
        // { label: "Vlr COFINS", key: "vcofins", sortable: true, tdClass: 'text-right', thClass: 'text-center' ,
        //   formatter: "formatCurrency"
        // },
        {
          label: "Status",
          key: "status",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listPedidos.length ? this.listPedidos.length : 0;
    },
    clonefoot() {
      return this.listPedidos.length || this.listPedidos.length > 10
        ? true
        : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value
        ? value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
        : "";
    },
    geraBoleto(objeto){
        console.log(objeto)
    },
    zipAndDownloadPdfs_blob() {
      console.log("estou no zip download blob");
      console.log(this.pdfArray);
      if (this.pdfArray.length === 0) {
        console.warn("Nenhum PDF armazenado para zipar.");
        return;
      }

      const zip = new JSZip();

      this.pdfArray.forEach((pdfBlob, index) => {
        const fileName = `documento_${index + 1}.pdf`;
        zip.file(fileName, pdfBlob);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "documentos.zip");
      });
    },
    async post_xml_pdf(objeto) {
      console.log("estou no post downlaod");
      console.log(objeto.xml);
      objeto.empresa_id = this.currentEmpresa.id;

      try {
        await http
          .post("nfe/downloadXml", objeto, { responseType: "arraybuffer" })
          .then((res) => {
            let resp = res.data;
            // this.pagamentos = res.data ? res.data : null;
            console.log("Mostrando pagamento no index");
            console.log(resp);
            //   const parser = new DOMParser();

            this.makeToast("success", "baixando PDF");
            const blob = new Blob([res.data], { type: "application/pdf" }
            );
            this.pdfArray.push(blob);
            console.log("mostrando array de Blobs")
            console.log(this.pdfArray)
            // Cria um blob a partir dos dados do PDF
            // const link = document.createElement("a"); // Cria um elemento <a> para o link de download
            // link.href = window.URL.createObjectURL(blob); // Define o URL do link para o blob do PDF
            // link.download = "documento.pdf"; // Define o nome do arquivo para download

            // link.click(); //

            // const zip = new JSZip();
            // zip.file("documento.pdf", blob);

            // zip.generateAsync({ type: "blob" })
            //   .then((content) => {
            //     saveAs(content, "documento.zip");
            //   });
            // // Parseie a string XML para um documento XML
            // const xmlDoc = parser.parseFromString(resp, "text/xml");

            // // Obtenha o valor de xMotivo
            // // const xMotivo =
            // //   xmlDoc.getElementsByTagName("xMotivo")[0].childNodes[0].nodeValue;

            // const xMotivos = xmlDoc.getElementsByTagName("xMotivo");

            // // Verifique se há pelo menos duas ocorrências de <xMotivo>
            // if (xMotivos.length >= 1) {
            //   // Acesse o valor da segunda tag <xMotivo>
            //   var segundoXMotivo = xMotivos[0].textContent;

            //   console.log("Valor da segunda tag <xMotivo>:", segundoXMotivo);
            // } else {
            //   console.log("A segunda tag <xMotivo> não foi encontrada.");
            // }
            // this.makeToast("success", segundoXMotivo);
            // this.makeToast("info", resp);
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
     enviar_pdfs_xmls() {
      let xmls = [...this.xmlStrings];
      console.log(
        "mostrnado o xmls para serem convertidos em pdf e retornar um zip ou bin"
      );
      console.log(xmls);
      let objeto = {};

      xmls.forEach((item) => {
        console.log(item);
        objeto.xml = item.content;
         this.post_xml_pdf(objeto);
      });
     // await Promise.all(promises);
      this.zipAndDownloadPdfs_blob();
      console.log("mostrando Objetos com vetor de xmls");
      console.log(objeto);
      //     try {

      //       let response = await http.post(
      //         "/nfe/xmlpdf?empresa_id=" + this.currentEmpresa.id,
      //         objeto
      //       ).then(response => {
      //   // Manipule o arquivo ZIP retornado
      //    console.log("mostrado vetor retorna")
      //    console.log(response.data)
      //   // const blob = new Blob([response.data], { type: 'application/zip' });
      //   // const link = document.createElement('a');
      //   // link.href = URL.createObjectURL(blob);
      //   // link.download = 'xmls.zip';
      //   // link.click();
      // })
      // .catch(error => {
      //   console.error(error);
      // });
      //       if (response.status === 200) {
      //         response.data;

      //          console.log(response.data)

      //          // tempo para carregar a tela;
      //       }
      //     } catch (error) {

      //       if (error.response) {
      //         if (error.response.status === 404) {
      //           this.makeToast("danger", "Destino URL não encontrado!");
      //         } else {
      //           this.makeToast("danger", error.response);
      //         }
      //       }
      //     }
    },
    async downloadZippedFiles() {
      const zip = new JSZip();

      this.xmlStrings.forEach((file) => {
        zip.file(file.name, file.content);
      });

      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(blob, "xml_files.zip");
    },
    seleciona_checkbox_todos(objeto) {
      console.log("Mostrando  objeto do Checkbox");
      console.log(objeto);

      if (objeto == true) {
        console.log(this.listPedidos);
        let vet = [...this.listPedidos];
        vet.forEach((item, index) => {
          let obejto_xml = {
            name: "arquivo" + index + ".xml",
            content: item.xml,
          };
          this.xmlStrings.push({ ...obejto_xml });
          this.listPedidos[index].check = true;
        });
        console.log("Selecionando todos");
        this.check_boll = false;
        setTimeout(() => {
          this.check_boll = true;
          this.button_boleano = true;
        }, 800);
      } else {
        let vet = [...this.listPedidos];
        vet.forEach((item, index) => {
          this.listPedidos[index].check = false;
        });
        console.log("Desmarcando todos");
        this.check_boll = false;
        this.button_boleano = false;
        setTimeout(() => {
          this.check_boll = true;
        }, 800);
      }
    },
    seleciona_checkbox(objeto, event, index) {
      console.log(index);
      console.log("Mostrando  objeto do Checkbox Unico");
      console.log(objeto);
      console.log("Mostrando o Evento:");
      console.log(event);
      let obejto_xml = {
        id: objeto.id,
        name: "arquivo" + index + ".xml",
        content: objeto.xml,
      };
      if (event == true) {
        this.xmlStrings.push({ ...obejto_xml });
        console.log(this.xmlStrings);
      } else {
        this.xmlStrings = this.xmlStrings.filter(
          (onjeto) => onjeto.id !== obejto_xml.id
        );

        console.log(this.xmlStrings);
      }
    },
    excluirNfe(objeto) {
      console.log("estou em exluir nfe");
      console.log(objeto);

      this.$emit("doDeletenfe", objeto);
    },
    back2() {
      this.$emit("back2");
    },
    importa_pedido_xml(objeto) {
      console.log(objeto);
      // document.getElementById("fileInput").click();
    },
    selecinando_xml_nfe(objeto) {
      console.log("estou no selecionando objeto xml list ");
      console.log(objeto);
      this.$emit("selecinando_xml_nfe", objeto);
    },
    handleFileChange(event) {
      const file = Array.from(event.target.files);
      this.files = file;
      console.log("estou no hanfle mostrando os files");
      console.log(this.files);
      // if (file) {
      //   this.readFileContent(file)
      //     .then((content) => {
      //       this.fileContent = content;
      //       this.ler_xml();
      //     })
      //     .catch((error) => {
      //       console.error("Erro ao ler o conteúdo do arquivo:", error);
      //     });
      // }
    },

    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          resolve(e.target.result);
        };

        reader.onerror = (e) => {
          reject(e.target.error);
        };

        reader.readAsText(file);
      });
    },
    ler_xml() {
      // console.log("estou em ler xml ");
      console.log(this.fileContent);
      if (typeof this.fileContent === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = this.fileContent;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let listaNomes = [];

      nomes.forEach((nome) => {
        listaNomes.push(nome.textContent);
      });
      console.log(listaNomes);
    },
    confirmar_integracao(objeto) {
      console.log("Confirmando integração xml gerando");
      console.log(objeto);
      let xmlDocument = new DOMParser().parseFromString(objeto.xml, "text/xml");
      console.log(xmlDocument);
      let nNf = xmlDocument.querySelector("nNF");
      let dhEmi = xmlDocument.querySelector("dhEmi");
      let vNF = xmlDocument.querySelector("vNF");
      let tot = xmlDocument.querySelector("total");
      let vprod = tot.querySelector("ICMSTot > vProd").textContent;
      console.log(objeto.id);
      console.log(nNf.textContent);
      console.log(dhEmi.textContent);
      console.log(vNF.textContent);
      console.log(vprod);

      let novoXml = `
        <NFe>
            <confirmation versao="1.00">
                <cPed>${objeto.id}</cPed>
                <numNF>${nNf.textContent}</numNF>
                <dhEmissao>${
                  new Date(dhEmi.textContent).toISOString().split("T")[0]
                }</dhEmissao>
                <vTotalProdutos>${parseFloat(vprod).toFixed(2)}</vTotalProdutos>
                <vTotalGeral>${parseFloat(vNF.textContent).toFixed(
                  2
                )}</vTotalGeral>
            </confirmation>
        </NFe>
    `;

      console.log(novoXml);

      // Criar um link para download do novo XML
      let blob = new Blob([novoXml], { type: "application/xml" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "novo_documento.xml";
      link.click();
    },
    downloadPDF(event) {
      //console.log("estou em downlaod");
      console.log(event);
      this.$emit("post_download_nfe_pdf", event);
    },
    showpdf(event) {
      console.log("esout no show pdf");
      this.objeto_show_pdf = event;
      console.log(this.objeto_show_pdf);
    },
    downloadXML(event) {
      console.log("estou em XML");
      console.log(event);
      // this.$emit('post_download_nfe_pdf',event)
      this.$emit("downloadXML", event);
    },
    formatDateBR(value) {
      return value ? moment(value).format("DD/MM/yyyy HH:mm") : "";
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPedido(pedido) {
      this.currentPedido = pedido;
      this.$emit("setPedido", pedido);
    },
    cartaCorre(carta) {
      this.$emit("cartaCorre", carta);
    },
    editar(objeto) {
      console.log("Editando NFe");
      console.log(objeto);
      this.$emit("edit", objeto);
      // if (pedido.tpnf === 0) {
      //   this.$emit("editCompra", pedido);
      // }
    },
    cancelar(nfe) {
      this.$emit("cancelarNf", nfe);
    },
    excluir(pedido) {
      this.$emit("doDelete", pedido);
    },
  },
};
</script>

<template>
  <div class="card-body">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="compra-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            {{ $t("mesages.table.filtered.view") }}&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;{{ $t("mesages.table.filtered.records") }}
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="compra-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            {{ $t("mesages.table.search") }}:
            <b-form-input
              v-model="filter"
              type="search"
              :placeholder="$t('mesages.table.search') + '...'"
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div
      class="row d-flex justify-content-center align-items-center"
      v-if="button_boleano == true || xmlStrings.length > 1"
    >
      <div class="col-md-12 text-center">
        <button class="btn btn-info" @click.prevent="downloadZippedFiles()">
          Download Arquivos Xml Zip
        </button>
        <button class="btn btn-danger ml-2" @click.prevent="enviar_pdfs_xmls()">
          Download Arquivos PDF Zip
        </button>
      </div>
    </div>
    <div class="row mt-4" v-if="check_boll">
      <div class="table mb-0">
        <b-table
          :items="listPedidos"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :busy="isBusy"
          show-empty
          :empty-text="$t('mesages.table.empty')"
        >
          <template #cell(fornecedor_id)="row">
            {{
              row.item.destinatario
                ? row.item.destinatario.pes_apelido
                : "Fornecedor não informado"
            }}
          </template>
          <template #head(check)="data">
            <b-form-checkbox
              v-model="select_todos_checks"
              @change="seleciona_checkbox_todos($event)"
            >
              {{ data.label == "Check" ? "Todos" : "Nada" }}
            </b-form-checkbox>
          </template>
          <template #cell(check)="row">
            <p class="fonte">
              <b-form-checkbox
                v-model="row.item.check"
                @change="seleciona_checkbox(row.item, $event, row.index)"
              >
              </b-form-checkbox>
              <!-- {{ .check }} -->
            </p>
          </template>
          <template #cell(nome_destinatario)="row">
            <p class="fonte">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                variant="info"
              >
                {{ row.item.nome_destinatario }}
              </b-badge>
            </p>
          </template>
          <template #cell(status)="row">
            <p class="fonte">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                :variant="
                  row.item.status == 1
                    ? 'success'
                    : row.item.status == 0
                    ? 'danger'
                    : 'warning'
                "
              >
                {{
                  row.item.status == 1
                    ? "Autorizado"
                    : row.item.status == 0
                    ? "Cancelada"
                    : "Pendente"
                }}
              </b-badge>
            </p>
          </template>
          <template #cell(dhRecbto)="row">
            <p class="fonte">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                variant="warning"
              >
                {{ formatDateTimeBR(row.item.dhRecbto) }}
              </b-badge>
            </p>
          </template>
          <template #cell(chNFe)="row">
            <b-badge
              data-name="Total"
              class="field-status fonte"
              variant="info"
            >
              {{ row.item.chNFe }}
            </b-badge>
          </template>
          <template #cell(valor)="row">
            <p class="fonte">
              <b-badge
                data-name="Total"
                class="field-status fonte"
                variant="success"
              >
                {{
                  typeof row.item.valor == "number"
                    ? formatterCurrBR(row.item.valor)
                    : "0,00"
                }}
              </b-badge>
            </p>
          </template>
          <template #cell(nProt)="row">
            <b-badge
              data-name="Total"
              class="field-status fonte"
              variant="warning"
            >
              {{ row.item.nProt }}
            </b-badge>
          </template>
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown
              v-bind:id="'dropdown-' + row.item.id"
              class="m-md-2"
              right
              text="Right align"
            >
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item
                v-if="row.item.status != 2"
                v-b-modal.visualizarPdf
                @click="showpdf(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status != 2"
                v-b-modal.boletoRemessa
                @click="geraBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i>Gerar Boleto</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status == 2"
                @click="editar(row.item)"
                ><i class="bx ri-edit-2-line"></i> Editar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status == 2"
                @click="excluirNfe(row.item)"
                ><i class="bx ri-edit-2-line"></i> Excluir</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status != 2"
                @click="cancelar(row.item)"
                ><i class="bx ri-edit-2-line"></i> Cancelar
                NF-e</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status != 2"
                @click="cartaCorre(row.item)"
                ><i class="bx ri-edit-2-line"></i> Carta de Correção
                NF-e</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status != 2"
                @click="downloadPDF(row.item)"
                ><i class="bx ri-edit-2-line"></i> Download PDF
              </b-dropdown-item>
              <b-dropdown-item
                v-if="row.item.status != 2"
                @click="downloadXML(row.item)"
                ><i class="bx ri-edit-2-line"></i> Download XML
              </b-dropdown-item>
              <b-dropdown-item
                v-if="row.item.status != 2"
                @click="confirmar_integracao(row.item)"
                ><i class="bx ri-eraser-fill"></i>Confirm Intg</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status != 2"
                v-b-modal.visualizarUploadsXml
                @click="importa_pedido_xml(row.item)"
                ><i class="bx ri-eraser-fill"></i>Import Ped.</b-dropdown-item
              >
              <!-- <input
                
                @change="handleFileChange"
                type="file"
                id="fileInput"
                style="display: none"
                webkitdirectory
                directory
                multiple
              /> -->
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->

    <b-modal hide-footer size="xl" id="visualizarPdf" title-class="font-18">
      <PDF :objeto_show_pdf="objeto_show_pdf" />
    </b-modal>

    <b-modal hide-footer size="xl" id="boletoRemessa" title-class="font-18">
      <Boleto />
    </b-modal>
    <!-- Start Modal Compra -->
    <!-- visulaiza arquivos de upload -->
    <b-modal
      hide-footer
      size="xl"
      id="visualizarUploadsXml"
      title-class="font-18"
      title="Upload"
      @hidden="back2()"
    >
      <MyView @selecinando_xml_nfe="selecinando_xml_nfe" />
    </b-modal>
    <!-- visulaiza arquivos de upload -->
    <b-modal hide-footer size="xxl" id="modal-compra" title-class="font-18">
      <template #modal-header="{ close }">
        <div class="row flex-nowrap align-items-center px-4 pt-3 pb-2">
          <div class="col p-0" style="min-width: 0px">
            <div class="d-flex align-items-center w-100">
              <button class="btn btn-link">
                <i
                  @click="close()"
                  title="Voltar"
                  class="fas fa-arrow-left mr-3"
                ></i>
              </button>
              <i title="Compra" class="mb-1 mr-1 fas fa-industry h5"></i>
              <div
                class="h5 d-flex align-items-center px-2 rounded text-truncate detail-header-title"
              >
                Número da Nota: {{ currentPedido.nnf }}
              </div>
              <h5 class="pl-2">
                <b-badge variant="secondary" class="pt-1 mr-2 ml-1">{{
                  formatterCurrBR(currentPedido.valortotalnota)
                }}</b-badge>
              </h5>
            </div>
          </div>
        </div>
      </template>

      <Detail :compra="currentPedido" />
    </b-modal>
    <!-- End Modal Compra -->
  </div>
</template>

<style>
.nameOfPersona {
  min-width: 350px;
}
.modal-xxl {
  max-width: 100%;
  margin: 20px 32px 0px 24px;
}
.fonte {
  font-size: 11px;
}
</style>