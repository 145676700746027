<script>
import moment from "moment";
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import EntradaBalanca from "./entrada-balanca";
import InsertCompra from "./insert-compra";
import EditCompra from "./edit-compra";
//import Teste from "./teste";
import NFE from "./NfeSimples.vue";

import Manifesto from "./manifesto.vue";
import Carta from "./carta.vue";
import ListCertificados from "./list_certificados/listaCertificados";
import Edit_nfe from "./NfeSimples_edit.vue";

export default {
  page: {
    title: "Pedidos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  // , Teste
  // NFE,
  components: {
    NFE,
    Manifesto,
    Layout,
    PageHeader,
    List,
    EntradaBalanca,
    InsertCompra,
    EditCompra,
    Carta,
    ListCertificados,
    Edit_nfe,
  },
  data() {
    return {
      objeto_edit_nfe: null,
      objeto_xml: null,
      abr_certificado: false,
      array_uploads: [],
      certificado: {
        razao_social: null,
      },
      certificado_bol: false,
      password: null,
      file: null,
      produtos: [],
      loader: {
        get: false,
      },
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      Lista_Produtos: [],
      listPedidos: [],
      currentPedido: {
        id: null,
        pessoa_id: null,
        nnf: null,
        tpnf: 0,
        tipopgto_id: null,
        qtde_parcelas: 1,
        historicopdr_id: null,
        tpmovimento_id: null,
        loc_id: null,
      },
      searchCompras: {},
      titleBody: "Listagem dos Pedidos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pedidos",
          href: "/financeiro/pedidos",
          active: true,
        },
      ],
      inserindo: false,
      editando: false,
      hideSearches: false,
      insertingCompra: false,
      editingCompra: false,
      entradaBalancaVisible: false,
      itenVisible: false,
      listTabelas: undefined,
      xml: false,
      pedido: false,
      abrir: false,
      historicos: [],
      movimentos: [],
      estoques: [],
      caixas: [],
      ct_custos: null,
      ct_resultados: null,
      pagamentos: [],
      pessoas: [],
      open_cart: false,
      carta_objeto: null,
      listUnidades: [],
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando && !this.xml && !this.pedido;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.searchCompras.fromData = moment()
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    this.searchCompras.toData = moment()
      .endOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    this.currentPedido.dh_saient = this.searchCompras.toData;
    this.currentPedido.dataemissao = this.searchCompras.toData;
  },
  mounted() {
    console.log("MOSTRANDO EMPRESA NO INDEX ");
    console.log(this.currentEmpresa.id);
    this.getData(); // Lemvbrar que posso pegar o vetor de produtos pelo metodo get,
    // utilizando o Axios para fazer a requisão para produtos;
    this.get_list_certificados();
    this.getData_produto();
    this.get_historicos();
    this.get_tipo_movimento();
    this.get_estoques();
    this.get_caixas();
    this.get_cent_custo();
    this.get_cent_resultados();
    this.get_tipo_pagamento();
    this.get_pessoas();
    this.getUnidades();
    //this.get_list_certificados();

    //this.getData_produto();
    //console.log(this.currentEmpresa)
    //console.log(this.currentUser)
  },
  methods: {
    async pesquisa_ibpt_produto() {
      try {
        // let objeto = {
        //   cnpj: 50625040000105,
        //   uf: "BA",
        // };
        let response = await http.get(
          "/nfe/pesquisa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          response.data;
          console.log("mostrando NFes");
          console.log(response.data);
          this.makeToast("danger", response.data);
          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.listPedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async pesquisa_cnpj_nsu() {
      try {
        let objeto = {
          cnpj: 50625040000105,
          uf: "BA",
        };
        let response = await http.post(
          "/nfe/nsu?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response.status === 200) {
          response.data;
          console.log("mostrando NFes");
          console.log(response.data);
          this.makeToast("danger", response.data);
          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.listPedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    selecinando_xml_nfe(objeto) {
      console.log("estou no selcionando objeto  xml index");
      console.log(objeto);
      this.$emit("selecinando_xml_nfe", objeto);
      this.objeto_xml = objeto;
      this.pedido = !this.pedido;
    },
    deleta_certificado(objeto) {
      this.makeToast("warning", "estou em deletar certificado index");
      console.log(objeto);
      this.doDelete(objeto);
    },
    async post_certificado(objeto) {
      console.log("esrou no post certificado do index");
      //console.log(objeto);
      //this.makeToast('warning','estou no indexe de certificado');
      let response = await http
        .post("/nfe/selecionaCert?empresa_id=" + this.currentEmpresa.id, objeto)
        .catch((error) => {
          this.error(error.response);
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        // this.getData();
        // this.back();
        //this.offLoader();
        console.log(response.data);
        this.makeToast("success", "Configuração selecionad" + response.data);
      }
    },
    async get_list_certificados() {
      //this.onLoader();
      try {
        let response = await http.get(
          "/nfe/getupload?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.array_uploads = response.data;
          console.log("Mostrando certificado no index");
          console.log(this.array_uploads);
          //this.abr_certificado = true;
          // tempo para carregar a tela;
        } else {
          //this.array_uploads = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //  this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      // console.log(this.file);
      // console.log(this.file.name);
    },
    async uploadFile() {
      console.log("estou aqui no upload ");
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("password", this.password);
      formData.append("razao", this.certificado.razao_social);
      formData.append("uf", this.certificado.uf);
      formData.append("cnpj", this.certificado.cnpj);
      formData.append("empresa_id", this.currentEmpresa.id);
      //console.log(formData)

      // for (let [key, value] of formData.entries()) {
      //   // Verifica se o valor é um objeto File
      //   if (value instanceof File) {
      //     console.log(`${value.name} ${key}`);
      //   }
      // }
      this.abr_certificado = false;
      try {
        const response = await http.post("/nfe/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status == 200) {
          this.get_list_certificados();

          this.makeToast("success", "upload feito");
        }
        setTimeout(() => {
          this.abr_certificado = true;
        }, 1000);

        console.log(response.data);
      } catch (error) {
        console.error("Erro no upload:", error);
      }
    },
    redirecionarParaOutraRota() {
      // Aqui você pode usar o método router.push para mudar a rota
      this.$router.push("/cadastros/item"); // Altere 'outra-rota' para o caminho da rota desejada
    },
    async get_pessoas() {
      // console.log("entrando em pressoas")
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.pessoas = response.data; //
          // console.log(this.pessoas);
        } else {
          this.pessoas = [];
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    importarXML() {
      this.abrir = true;
      this.xml = !this.xml;
    },
    // usado para alterar o variavel boelana de pedido;
    func_ped() {
      if (this.array_uploads.length == 0) {
        this.makeToast("danger", "Faça o Upload do Certificado Digital");
      } else {
        this.pedido = !this.pedido;
      }
    },
    back() {
      this.abrir = false;
      this.changeHomeTitle("Listagem dos Pedidos");
      this.inserindo = false;
      this.insertingCompra = false;
      this.editingCompra = false;
      this.entradaBalancaVisible = false;
      this.itenVisible = false;
      this.editando = false;
      this.xml = false;
      this.pedido = false;
      this.open_cart = false;
      this.objeto_xml = null;
      this.getData();
    },
    back2() {
      this.abrir = false;
      this.changeHomeTitle("Listagem dos Pedidos");
      this.inserindo = false;
      this.insertingCompra = false;
      this.editingCompra = false;
      this.entradaBalancaVisible = false;
      this.itenVisible = false;
      this.editando = false;
      this.xml = false;
      this.pedido = false;
      this.open_cart = false;
      this.getData();
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
      //this.certificado_bol = !this.certificado_bol;
    },
    ativa_modal_cer() {
      console.log("estou aqui");
      this.certificado_bol = !this.certificado_bol;
      this.abr_certificado = !this.abr_certificado;

      this.get_list_certificados();

      // console.log(this.certificado_bol);
    },
    clearNewPedido() {
      let today = new Date();
      this.currentPedido.id = null;
      this.currentPedido.pessoa_id = null;
      this.currentPedido.nnf = null;
      this.currentPedido.tpnf = 0;
      this.currentPedido.tipopgto_id = null;
      this.currentPedido.qtde_parcelas = 1;
      this.currentPedido.historicopdr_id = null;
      this.currentPedido.tpmovimento_id = null;
      this.currentPedido.loc_id = null;
      this.currentPedido.dh_saient = moment(today).format("yyyy-MM-ddThh:mm");
      this.currentPedido.dataemissao = moment(today).format("yyyy-MM-ddThh:mm");
    },
    async getData_produto() {
      this.onLoader();

      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.Lista_Produtos = response.data;
          // console.log(this.Lista_Produtos)
          setTimeout(() => {
            this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.Lista_Produtos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_caixas() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "/movimento-caixa?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.caixas = response.data;
          //console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;
        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_historicos() {
      try {
        await http
          .get("/historicopadrao?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.historicos = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_cent_custo() {
      try {
        await http
          .get("/centrocusto?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.ct_custos = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_cent_resultados() {
      try {
        await http
          .get("/centroresultado?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.ct_resultados = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_tipo_movimento() {
      try {
        await http
          .get("/tipomovimento?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.movimentos = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_tipo_pagamento() {
      try {
        await http
          .get("/tipopagamento?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.pagamentos = res.data ? res.data : null;
            console.log("Mostrando pagamento no index");
            console.log(this.pagamentos);
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async post_download_nfe_pdf(objeto) {
      console.log("estou no post downlaod");
      console.log(objeto);

      try {
        await http
          .post("nfe/downloadXml", objeto, { responseType: "arraybuffer" })
          .then((res) => {
            let resp = res.data;
            // this.pagamentos = res.data ? res.data : null;
            console.log("Mostrando pagamento no index");
            console.log(resp);
            //   const parser = new DOMParser();

            this.makeToast("success", "baixando PDF");
            const blob = new Blob([res.data], { type: "application/pdf" }); // Cria um blob a partir dos dados do PDF
            const link = document.createElement("a"); // Cria um elemento <a> para o link de download
            link.href = window.URL.createObjectURL(blob); // Define o URL do link para o blob do PDF
            link.download = "documento.pdf"; // Define o nome do arquivo para download
            link.click(); //
            // // Parseie a string XML para um documento XML
            // const xmlDoc = parser.parseFromString(resp, "text/xml");

            // // Obtenha o valor de xMotivo
            // // const xMotivo =
            // //   xmlDoc.getElementsByTagName("xMotivo")[0].childNodes[0].nodeValue;

            // const xMotivos = xmlDoc.getElementsByTagName("xMotivo");

            // // Verifique se há pelo menos duas ocorrências de <xMotivo>
            // if (xMotivos.length >= 1) {
            //   // Acesse o valor da segunda tag <xMotivo>
            //   var segundoXMotivo = xMotivos[0].textContent;

            //   console.log("Valor da segunda tag <xMotivo>:", segundoXMotivo);
            // } else {
            //   console.log("A segunda tag <xMotivo> não foi encontrada.");
            // }
            // this.makeToast("success", segundoXMotivo);
            // this.makeToast("info", resp);
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    downloadXML(objeto) {
      console.log(objeto);
      let xml_srting = objeto.xml;
      let blob = new Blob([xml_srting], { type: "text/xml" });
      let url = window.URL.createObjectURL(blob);

      // Criando um link para download
      let a = document.createElement("a");
      a.href = url;
      a.download = "arquivo.xml";
      a.click();

      // Removendo o objeto URL após o download
      window.URL.revokeObjectURL(url);
    },
    async get_estoques() {
      try {
        await http
          .get("/localestoque?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.estoques = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },

    // async getData() {
    //   //this.onLoader();

    //   try {
    //     // let toParams = {
    //     //   fromNNf: this.searchCompras.fromNNf,
    //     //   toNNf: this.searchCompras.toNNf,
    //     //   fromData: (this.searchCompras.fromData) ? moment(this.searchCompras.fromData).format('yyyy-MM-DD HH:mm:ss') : null,
    //     //   toData: (this.searchCompras.toData) ? moment(this.searchCompras.toData).format('yyyy-MM-DD HH:mm:ss') : null,
    //     //   destinatario: this.searchCompras.destinatario
    //     // };

    //    // console.log('Carregando dados...');
    //     let response = await http.get('nfe/teste2?empresa_id='+ this.currentEmpresa.id);
    //     ///console.log('Validando dados...');

    //     if (response.status === 200) {
    //       this.listPedidos = response.data;
    //       console.log(this.listPedidos)
    //     }
    //   } catch (error) {
    //     let messageErro = error.response.data;

    //     switch (error.response.status) {
    //       case 406:
    //         this.makeToast('danger', 'Erro 406: ' + (messageErro.nnf) ? messageErro.pessoa_id : messageErro.tipo_id);
    //         this.offLoader();
    //         break;
    //       case 404:
    //         this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
    //         this.offLoader();
    //         break;

    //       default:data
    //         this.makeToast('danger', error.message);
    //         this.offLoader();
    //         break;
    //     }
    //     this.loader.get = false;
    //   }

    //   this.loader.get = false;
    // },

    async getData() {
      this.onLoader();
      try {
        let response = await http.get(
          "/nfe/teste2?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.listPedidos = response.data;
          console.log("mostrando NFes");
          console.log(this.listPedidos);

          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.listPedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getUnidades() {
      this.onLoader();
      try {
        let response = await http.get(
          "/unidade?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.listUnidades = response.data;
          console.log(this.pre_compra);

          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.listUnidades = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },

    cartaCorre(carta) {
      console.log(carta);
      this.open_cart = true;
      this.carta_objeto = carta;
    },
    async cartaCorrecao(carta) {
      this.onLoader();
      try {
        let response = await http.post("/nfe/carta", carta);
        if (response.status === 200) {
          // this.listPedidos = response.data;
          // console.log(this.pre_compra);
          this.makeToast("success", response.data);
          setTimeout(() => {
            this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.listPedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    incluirEntradaBalanca() {
      this.entradaBalancaVisible = !this.entradaBalancaVisible;
    },
    async insertCompra() {
      this.onLoader();
      await this.getDadosGerais();
      this.clearNewPedido();
      this.inserindo = !this.inserindo;
      this.insertingCompra = !this.insertingCompra;
      this.offLoader();
    },
    async editCompra(pedido) {
      this.onLoader();
      this.currentPedido = pedido;
      await this.getDadosGerais();
      this.editando = !this.editando;
      this.editingCompra = !this.editingCompra;
      this.offLoader();
    },
    cancelarNf(nfe) {
      this.cancelarNfe(nfe);
      console.log("estou no cancelar NF");
      console.log(nfe);
    },
    async confirma_cancelar_BD(nfe) {
      console.log("estuo no confirma cancelar no BD");
      console.log(nfe);
      try {
        let response = await http.post("/nfe/confirmCancelar", nfe);
        if (response.status == 200) {
          // console.log(response.data);
          let msg = response.data;
          console.log(msg);

          this.makeToast("success", "Confirmado Cancelar no BD");
        } else {
          console.log(response.data);
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    async cancelarNfe(nfe) {
      console.log("esotu em cancelar NFE");
      console.log(nfe);

      try {
        let response = await http.post("/nfe/cancelar", nfe);
        if (response.status == 200) {
          // console.log(response.data);

          let msg = response.data;
          console.log(msg);

          //           const { Element } = require("xml-js");

          //           const xmlObj = Element.parse(msg, { compact: true });
          // //
          //           const xMotivo =
          //             xmlObj["soapenv:Envelope"]["soapenv:Body"]["nfeResultMsg"][
          //               "retEnvEvento"
          //             ]["xMotivo"];
          //             console.log(xMotivo)
          const parser = new DOMParser();

          // Parseie a string XML para um documento XML
          const xmlDoc = parser.parseFromString(msg, "text/xml");

          // Obtenha o valor de xMotivo
          // const xMotivo =
          //   xmlDoc.getElementsByTagName("xMotivo")[0].childNodes[0].nodeValue;

          const xMotivos = xmlDoc.getElementsByTagName("xMotivo");
          const cStat = xmlDoc.getElementsByTagName("cStat");
          console.log("Mostrando cStatus no cancelar");
          console.log(cStat[1].textContent);
          // Verifique se há pelo menos duas ocorrências de <xMotivo>

          if (cStat[1].textContent == "135") {
            nfe.status = 0;
            this.confirma_cancelar_BD(nfe);
          }
          if (xMotivos.length >= 2) {
            // Acesse o valor da segunda tag <xMotivo>
            var segundoXMotivo = xMotivos[1].textContent;

            console.log("Valor da segunda tag <xMotivo>:", segundoXMotivo);
          } else {
            console.log("A segunda tag <xMotivo> não foi encontrada.");
          }
          this.makeToast("success", segundoXMotivo);
        } else {
          console.log(response.data);
        }
      } catch (e) {
        console.log(e.data);
        this.makeToast("danger", e.data);
      }
    },
    edit(objeto) {
      console.log("estuo no edit nfe index");
      this.objeto_edit_nfe = objeto;
      console.log(this.objeto_edit_nfe);
      this.editando = !this.editando;
    },
    async entradaBalanca() {
      this.onLoader();
      await this.getDadosGerais();
      this.inserindo = !this.inserindo;
      this.entradaBalancaVisible = !this.entradaBalancaVisible;
      this.offLoader();
    },
    async insertItem() {
      // criado por vinicus
      this.onLoader();
      this.inserindo = !this.inserindo;
      this.itenVisible = !this.itenVisible;
      this.offLoader();
    },
    async getDadosGerais() {
      try {
        if (this.listTabelas == undefined) {
          await http
            .get("/tabelas/dadoscompras?empresa_id=" + this.currentEmpresa.id)
            .then((res) => {
              this.listTabelas = res.data;
            })
            .finally(() => {});
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async doPost() {
      let response = await http
        .post(
          "/pedido/?empresa_id=" + this.currentEmpresa.id,
          this.currentPedido
        )
        .catch((error) => {
          this.error(error.response);
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast("success", "Pedido incluído");
      }
    },
    async doPut() {
      let response = await http
        .put(
          "/pedido/?empresa_id=" + this.currentEmpresa.id,
          this.currentPedido
        )
        .catch((error) => {
          this.error(error.response);
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast("success", "Pedido alterado");
      }
    },
    async doDelete(certificado) {
      // console.log(pre_pedido);
      //this.onLoader();
      this.certificado_bol = false;
      try {
        let response = await http.delete(
          "/nfe/deleteCert/" +
            certificado.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          certificado
        );
        if (response) {
          if (response.status === 200) {
            this.get_list_certificados();

            this.makeToast("warning", "Registro excluído");
          }
          setTimeout(() => {
            this.certificado_bol = true;
          }, 1000);
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDeletenfe(nfe) {
      // console.log(pre_pedido);
      //this.onLoader();
      this.certificado_bol = false;
      try {
        let response = await http.delete(
          "/nfe/" + nfe.id + "?empresa_id=" + this.currentEmpresa.id,
          nfe
        );
        if (response) {
          if (response.status === 200) {
            //this.get_list_certificados();
            this.getData();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button
                  v-if="isHide"
                  class="btn btn-light"
                  @click="searchInputs()"
                >
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>

                <button
                  v-if="isHide"
                  class="btn btn-light ml-2"
                  @click="ativa_modal_cer()"
                >
                  <span
                    class="fa-sm fa"
                    :class="
                      certificado_bol ? 'fa-chevron-up' : 'fa-chevron-down'
                    "
                  ></span>
                  Carregar Certificado Digital
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right mt-4">
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click="func_ped()"
                >
                  + Incluir Pedido
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click="pesquisa_ibpt_produto()"
                >
                  + Consulta Ibpt
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click.prevent="pesquisa_cnpj_nsu()"
                >
                  + Consulta Nfe Por CNPJ
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click="redirecionarParaOutraRota()"
                >
                  + Incluir Pre-pedido
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click="entradaBalanca()"
                >
                  + Incluir Pesagem
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-success mr-1"
                  @click="insertCompra()"
                >
                  + Incluir Compra
                </button>
                <button
                  v-if="isHide"
                  class="btn btn-success"
                  @click="importarXML()"
                >
                  + Importar XML
                </button>
                <button
                  v-if="!isHide"
                  class="btn btn-secondary"
                  @click="back()"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">
                  Use esses campos para filtrar os registros
                </p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="searchCompras-status">Nº Documento</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              id="searchCompras-fromNNf"
                              v-model="searchCompras.fromNNf"
                              type="number"
                              class="form-control"
                              placeholder="Nº NF"
                            />
                          </div>
                          <div class="col-md-6">
                            <input
                              id="searchCompras-toNNf"
                              v-model="searchCompras.toNNf"
                              type="number"
                              class="form-control"
                              placeholder="Nº NF"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="searchCompras-fromData">Data saída</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              id="searchCompras-fromData"
                              v-model="searchCompras.fromData"
                              type="datetime-local"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                          <div class="col-md-6">
                            <input
                              id="searchCompras-toData"
                              v-model="searchCompras.toData"
                              type="datetime-local"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Fornecedor</label>
                        <input
                          id="searchCompras-destinatario"
                          v-model="searchCompras.destinatario"
                          type="text"
                          class="form-control"
                          placeholder="Nome do Fornecedor"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    @click="getData"
                    class="btn btn-primary"
                    type="button"
                  >
                    OK
                  </button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- {{ certificado_bol }} -->
          <div class="card-body" v-if="certificado_bol">
            <!-- Start Card -->

            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Certificado</h4>
                <p class="card-title-desc">
                  Use esses campos para preenhcer as informações do Certificado
                </p>
                <!-- <div class="row mb-4">
                  <div class="col-md-12">
                    <form @submit.prevent="uploadFile">
                      <input
                        type="file"
                        @change="handleFileUpload"
                        placeholder="selecione"
                        class="btn btn-secondary"
                      />
                      <input
                        type="text"
                        v-model="password"
                        placeholder="digite a senha"
                        class="estilo_input ml-2"
                      />
                      <button class="btn btn-secondary ml-2" type="submit">
                        Upload
                      </button>
                    </form>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="col-md-4">
                    <Label for="">{{"*Razão Social"}}</Label>
                    <input
                      v-model="certificado.razao_social"
                      type="text"
                      class="form-control text-left"
                      placeholder="Digite"
                    />
                  </div>
                  <div class="col-md-4">
                    <Label for="">{{"*CNPJ"}}</Label>
                    <input
                      v-model="certificado.cnpj"
                      type="text"
                      class="form-control text-left"
                      placeholder="Digite"
                    />
                  </div>
                  <div class="col-md-4">
                    <Label for="">{{"*UF"}}</Label>
                    <input
                      v-model="certificado.uf"
                      type="text"
                      class="form-control text-left"
                      placeholder="Digite"
                      maxlength="2"
                    />
                  </div>
                </div> -->
                <div class="row" v-if="abr_certificado">
                  <div class="col-md-12">
                    <ListCertificados
                      @deleta_certificado="deleta_certificado"
                      @post_certificado="post_certificado"
                      :array_certificados="array_uploads"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <div class="card-body" v-if="!isHide">
            <Manifesto
              v-if="xml"
              @back="back"
              @back2="back2"
              :abrir="abrir"
              :Lista_Produtos="Lista_Produtos"
              :pessoas_vet="pessoas"
            />
            <EntradaBalanca
              :newCompra="currentPedido"
              :oldItems="items"
              v-if="entradaBalancaVisible"
              :listTabelas="listTabelas"
            />
            <InsertCompra
              v-if="insertingCompra"
              :newPedido="currentPedido"
              :oldItems="items"
              :listTabelas="listTabelas"
              @newTitle="changeHomeTitle"
              :currentEmpresa="currentEmpresa"
              :onLoader="onLoader"
              :offLoader="offLoader"
              @doPost="doPost"
            />
            <EditCompra
              v-if="editingCompra"
              :currentPedido="currentPedido"
              :oldItems="items"
              :listTabelas="listTabelas"
              @newTitle="changeHomeTitle"
              :currentEmpresa="currentEmpresa"
              :onLoader="onLoader"
              :offLoader="offLoader"
              @doPut="doPut"
            />
            <!-- <InsertPedido v-if="itenVisible" 
               @newTitle="changeHomeTitle" :currentEmpresa="currentEmpresa"
              :onLoader="onLoader" :offLoader="offLoader" @doPost="doPost" /> -->
            <!-- :currentUser="currentUser" -->
            <!-- :currentEmpresa="currentEmpresa" -->
            <!-- <NFE :Lista_Produtos="Lista_Produtos" v-if="pedido" :p1="pessoas" :empresa_current="currentEmpresa" :currentUser="currentUser"   :pagamentos="pagamentos" :ct_resultados=ct_resultados :ct_custos="ct_custos" :caixas="caixas" :estoques="estoques" :movimentos="movimentos" :historicos="historicos"  /> -->
            <NFE
              :objeto_xml="objeto_xml"
              :listUnidades="listUnidades"
              :Lista_Produtos="Lista_Produtos"
              v-if="pedido"
              :p1="pessoas"
              :empresa_current="currentEmpresa"
              :currentUser="currentUser"
              :pagamentos="pagamentos"
              :ct_resultados="ct_resultados"
              :ct_custos="ct_custos"
              :caixas="caixas"
              :estoques="estoques"
              :movimentos="movimentos"
              :historicos="historicos"
              :uploads="array_uploads"
              :nfes_array="listPedidos"
              @getData="getData"
              @back2="back2"
              @selecinando_xml_nfe="selecinando_xml_nfe"
              @back="back"
            />
            <Edit_nfe
              :listUnidades="listUnidades"
              :Lista_Produtos="Lista_Produtos"
              v-if="editando"
              :p1="pessoas"
              :empresa_current="currentEmpresa"
              :currentUser="currentUser"
              :pagamentos="pagamentos"
              :ct_resultados="ct_resultados"
              :ct_custos="ct_custos"
              :caixas="caixas"
              :estoques="estoques"
              :movimentos="movimentos"
              :historicos="historicos"
              :uploads="array_uploads"
              :nfes_array="listPedidos"
              :objeto_pre_nfe="objeto_edit_nfe"
              @getData="getData"
              @back2="back2"
              @edit="edit"
              @selecinando_xml_nfe="selecinando_xml_nfe"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listPedidos="listPedidos"
            :isBusy="loader.get"
            @editCompra="editCompra"
            @cancelarNf="cancelarNf"
            @cartaCorre="cartaCorre"
            @post_download_nfe_pdf="post_download_nfe_pdf"
            @downloadXML="downloadXML"
            @selecinando_xml_nfe="selecinando_xml_nfe"
            @edit="edit"
            @back2="back2"
            @doDeletenfe="doDeletenfe"
          />
          <div v-if="open_cart">
            <Carta
              :carta="carta_objeto"
              :modal="open_cart"
              @back="back"
              @cartaCorrecao="cartaCorrecao"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>