<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <Label>Tipo Boleto</Label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="select_boleto"
          @change="seleciona_boleto($event)"
        >
          <option value="-1">selecione um valor</option>
          <option v-for="(item, index) in tipos_boletos" :key="index" :value="item.id">
            {{ item.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4" v-if="select_boleto == 1">
       <div class="col-md-12 text-center">
            <button class="btn btn-danger">Download Boleto Bradesco</button>
       </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4" v-if="select_boleto == 2">
       <div class="col-md-12 text-center">
            <button class="btn btn-warning">Download Boleto Banco Brasil</button>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipos_boletos: [
        {
          id: 1,
          nome: "Bradesco",
        },
        {
          id: 2,
          nome: "Banco do Brasil",
        },
      ],
      select_boleto:null
    };
  },
  methods:{
    seleciona_boleto(event){
      console.log("Mostrando tipo de boleto")
      console.log(event)
    }
  }
};
</script>

<style>
</style>